import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Tab } from 'carbon-components-react';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ResourceCard = makeShortcode("ResourceCard");
const Tabs = makeShortcode("Tabs");
const Video = makeShortcode("Video");
const DoDontExample = makeShortcode("DoDontExample");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h3 {...{
      "id": "motion-can-bring-the-screen-to-life-guide-users-through-complex-experiences-and-help-move-them-forwardfrom-here-to-there-now-to-next-start-to-finishand-make-progress"
    }}>{`Motion can bring the screen to life, guide users through complex experiences, and help move them forward—from here to there, now to next, start to finish—and make progress.`}</h3>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Carbon in motion</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Style</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Easing</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Duration</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "resources"
    }}>{`Resources`}</h2>
    <Row className="resource-card-group" mdxType="Row">
  <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ResourceCard subTitle="Motion Generator" href="https://ibm.github.io/motion/" mdxType="ResourceCard" />
  </Column>
  <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ResourceCard subTitle="IBM Motion Package" href="https://github.com/carbon-design-system/carbon/tree/master/packages/motion" mdxType="ResourceCard" />
  </Column>
    </Row>
    <br />
    <br />
    <h2 {...{
      "id": "carbon-in-motion"
    }}>{`Carbon in motion`}</h2>
    <p><a parentName="p" {...{
        "href": "../../components/overview/"
      }}>{`Carbon components`}</a>{` have motion built in for
microinteractions. However, the motion design of the overarching UI — that is,
how the components interact with each other and enter and exit the page itself —
is up to each product team to implement. Use this guidance to customize,
combine, coordinate, and choreograph this aspect of motion in the UI.`}</p>
    <h2 {...{
      "id": "style"
    }}>{`Style`}</h2>
    <p>{`Carbon recognizes different types of moments in users’ experience and offers two
styles of motion—`}<em parentName="p">{`productive`}</em>{` motion, and `}<em parentName="p">{`expressive`}</em>{` motion. The motion curves
are designed to reflect the duality of man and machine.`}</p>
    <p>{`Productivity and expression are both essential to an interface. Reserve
expressive motion for occasional, important moments, to better capture the
user’s attention and offer a rhythmic break to the productive experience.`}</p>
    <Tabs mdxType="Tabs">
      <Tab label="Curves" mdxType="Tab">
        <Row mdxType="Row">
          <Column colSm={6} colMd={4} colLg={6} mdxType="Column">
            <p>Productive</p>
            <img {...{
              "src": "/88eb36164b01911cdfbbfcadf25d450c/Motion_overview_Curves_productive.svg",
              "alt": "Curve graphs representing differences between productive and expressive animation curves"
            }}></img>
          </Column>
          <Column colSm={6} colMd={4} colLg={6} mdxType="Column">
            <p>Expressive</p>
            <img {...{
              "src": "/dc374d592da23460cf0079bb133577d6/Motion_overview_Curves_expressive.svg",
              "alt": "Curve graphs representing differences between productive and expressive animation curves"
            }}></img>
          </Column>
        </Row>
      </Tab>
      <Tab label="Abstract" mdxType="Tab">
        <Video vimeoId="310583009" mdxType="Video" />
      </Tab>
      <Tab label="In-situ" mdxType="Tab">
        <Video vimeoId="310583036" mdxType="Video" />
      </Tab>
    </Tabs>
    <h3 {...{
      "id": "productive-motion"
    }}>{`Productive motion`}</h3>
    <p>{`Productive motion creates a sense of efficiency and responsiveness, while remain
subtle and out of the way. Productive motion is appropriate for moments when the
user needs to focus on completing tasks. Microinteractions in Carbon such as
button states, dropdowns, revealing additional information, or rendering data
tables and visualizations were all designed with productive motion.`}</p>
    <h3 {...{
      "id": "expressive-motion"
    }}>{`Expressive motion`}</h3>
    <p>{`Expressive motion delivers enthusiastic, vibrant, and highly visible movement.
Use expressive motion for significant moments such as opening a new page,
clicking the primary action button, or when the movement itself conveys a
meaning. System alerts and the appearance of notification boxes are great cases
for expressive motion.`}</p>
    <DoDontExample type="do" fullWidth caption="Productive moments are labeled in blue, and expressive moments are labeled in magenta." mdxType="DoDontExample">
      <Video vimeoId="310583077" mdxType="Video" />
    </DoDontExample>
    <h2 {...{
      "id": "easing"
    }}>{`Easing`}</h2>
    <p>{`Strictly linear movement appears unnatural to the human eye. Elements on the
screen should speed up quickly and slow down smoothly, obeying the physics of a
light-weight material. “Easing curves” describe the precise amount of
accelerations in motion. We commonly use one of these three types of easing.`}</p>
    <Row mdxType="Row">
      <Column colLg={12} mdxType="Column">
        <DoDontExample type="do" fullWidth caption="Elements on the screen speed up quickly and slow down smoothly, obeying the physics of a light-weight material." mdxType="DoDontExample">
          <Video vimeoId="310582370" mdxType="Video" />
        </DoDontExample>
      </Column>
    </Row>
    <br />
    <p>{`Avoid easing curves that are unnatural, distracting, or purely decorative. IBM
motion is essential and efficient, guiding users to value as quickly as
possible.`}</p>
    <Row mdxType="Row">
      <Column colLg={12} mdxType="Column">
        <DoDontExample fullWidth caption="Do not use easing curves that suggest bounce, stretch, or sudden stops." mdxType="DoDontExample">
          <Video vimeoId="310582418" mdxType="Video" />
        </DoDontExample>
      </Column>
    </Row>
    <h3 {...{
      "id": "standard-easing"
    }}>{`Standard easing`}</h3>
    <p>{`Use `}<inlineCode parentName="p">{`standard-easing`}</inlineCode>{` when an element is visible from the beginning to the end
of a motion. Expanding tiles and the sorting of table rows are good examples.`}</p>
    <Tabs mdxType="Tabs">
      <Tab label="Curves" mdxType="Tab">
        <div>
          <Video vimeoId="310582611" mdxType="Video" />
        </div>
      </Tab>
      <Tab label="Examples" mdxType="Tab">
        <div>
          <Video vimeoId="310582667" mdxType="Video" />
        </div>
      </Tab>
    </Tabs>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Style`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`IBM Motion package`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`CSS`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`After Effects`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Productive`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`motion(standard, productive)`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`cubic-bezier(0.2, 0, 0.38, 0.9)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`outgoing 20%, incoming 62%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Expressive`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`motion(standard, expressive)`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`cubic-bezier(0.4, 0.14, 0.3, 1)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`outgoing 40%, incoming 70%`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "entrance-easing"
    }}>{`Entrance easing`}</h3>
    <p>{`With this style, an element quickly appears and slows down to a stop. Use
`}<inlineCode parentName="p">{`entrance-ease`}</inlineCode>{` when adding elements to the view, such as a modal or toaster
appearing. Elements moving in response to the user’s input, such as a dropdown
opening or toggle switching should also use this style.`}</p>
    <Tabs mdxType="Tabs">
      <Tab label="Curves" mdxType="Tab">
        <div>
          <Video vimeoId="310582467" mdxType="Video" />
        </div>
      </Tab>
      <Tab label="Examples" mdxType="Tab">
        <div>
          <Video vimeoId="310582503" mdxType="Video" />
        </div>
      </Tab>
    </Tabs>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Style`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`IBM Motion package`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`CSS`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`After Effects`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Productive`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`motion(entrance, productive)`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`cubic-bezier(0, 0, 0.38, 0.9)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`outgoing 0%, incoming 62%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Expressive`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`motion(entrance, expressive)`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`cubic-bezier(0, 0, 0.3, 1)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`outgoing 0%, incoming 70%`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "exit-easing"
    }}>{`Exit easing`}</h3>
    <p>{`Use `}<inlineCode parentName="p">{`exit-easing`}</inlineCode>{` when removing elements from view, such as closing a modal or
toaster. The element speeds up as it exits from view, implying that its
departure from the screen is permanent.`}</p>
    <p>{`An exception to exits: if an element leaves the view but stays nearby, ready to
reappear upon user action, use standard easing instead. A good example of this
is a side panel. The panel leaves the view, but slows down as it exits, implying
that it would come to rest just outside the view, and ready to be recalled.`}</p>
    <Tabs mdxType="Tabs">
      <Tab label="Curves" mdxType="Tab">
        <div>
          <Video vimeoId="310582530" mdxType="Video" />
        </div>
      </Tab>
      <Tab label="Examples" mdxType="Tab">
        <div>
          <Video vimeoId="310582574" mdxType="Video" />
        </div>
      </Tab>
    </Tabs>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Style`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`IBM motion package`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`CSS`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`After Effects`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Productive`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`motion(exit, productive)`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`cubic-bezier(0.2, 0, 1, 0.9)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`outgoing 20%, incoming 0%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Expressive`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`motion(exit, expressive)`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`cubic-bezier(0.4, 0.14, 1, 1)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`outgoing 40%, incoming 0%`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "summary-on-easing"
    }}>{`Summary on easing`}</h3>
    <p>{`The
`}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-elements/tree/master/packages/motion"
      }}>{`IBM motion package`}</a>{`
has the easing curves stored for fast access. This is the recommended method to
call an easing curve and stay connected to receive any future updates.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Easing curve`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Productive`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Expressive`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Standard easing`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`motion(standard, productive)`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`motion(standard, expressive)`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Entrance easing`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`motion(entrance, productive)`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`motion(entrance, expressive)`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Exit easing`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`motion(exit, productive)`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`motion(exit, expressive)`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <p>{`If you are unable to use the above method, cubic-bezier curve notation is the
standard way to express easing curves.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Easing curve`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Productive`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Expressive`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Standard easing`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`cubic-bezier(0.2, 0, 0.38, 0.9)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`cubic-bezier(0.4, 0.14, 0.3, 1)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Entrance easing`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`cubic-bezier(0, 0, 0.38, 0.9)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`cubic-bezier(0, 0, 0.3, 1)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Exit easing`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`cubic-bezier(0.2, 0, 1, 0.9)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`cubic-bezier(0.4, 0.14, 1, 1)`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "duration"
    }}>{`Duration`}</h2>
    <p>{`Duration is calculated based on the style and size of the motion. Among the two
motion styles, productive motion is significantly faster than expressive motion.
Motion’s duration should be dynamic based on the size of the animation; the
larger the change in distance (traveled) or size (scaling) of the element, the
longer the animation takes.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <DoDontExample type="do" caption="Duration contrast between a taller and a shorter component." mdxType="DoDontExample">
          <Video vimeoId="310582312" mdxType="Video" />
        </DoDontExample>
      </Column>
    </Row>
    <br />
    <p>{`When custom duration is possible, please use the
`}<a parentName="p" {...{
        "href": "https://ibm.github.io/motion/"
      }}>{`Motion Generator`}</a>{` to get the customized duration
calculated for your element. Carbon uses a non-linear duration scale to achieve
better perceived consistency across all distances.`}</p>
    <Row className="resource-card-group" mdxType="Row">
  <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ResourceCard subTitle="Motion Generator" href="https://ibm.github.io/motion/" mdxType="ResourceCard" />
  </Column>
    </Row>
    <br />
    <h3 {...{
      "id": "duration-tokens"
    }}>{`Duration tokens`}</h3>
    <p>{`Dynamic duration is an upcoming built-in feature for Carbon components and a
part of the motion package. Currently, there are six static value tokens for
easier implementation.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Token`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`duration--fast-01`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Micro-interactions such as button and toggle`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`70ms`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`duration--fast-02`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Micro-interactions such as fade`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`110ms`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`duration--moderate-01`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Micro-interactions, small expansion, short distance movements`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`150ms`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`duration--moderate-02`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Expansion, system communication, toast`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`240ms`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`duration--slow-01`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Large expansion, important system notifications`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`400ms`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`duration--slow-02`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Background dimming`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`700ms`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      